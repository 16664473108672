// Earthcss.jsx

import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import EarthTexture from '../../images/Scene_-_Root_baseColor.jpeg';
import '../../styles/MainPages/Earthcss.scss';

const Earthcss = () => {
    const mountRef = useRef(null);

    useEffect(() => {
        // Scene setup
        const scene = new THREE.Scene();
        scene.background = null; // Set background to transparent
    
        // Camera setup
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        camera.position.z = 10; // Move camera back to fit larger Earth
    
        // Renderer setup
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.shadowMap.enabled = true; // Enable shadow mapping
        renderer.setClearColor(0x000000, 0); // Set background color to transparent
        mountRef.current.appendChild(renderer.domElement);
    
        // Texture loader
        const textureLoader = new THREE.TextureLoader();
        const earthTexture = textureLoader.load(EarthTexture);
    
        // Earth geometry and material
        const geometry = new THREE.SphereGeometry(4, 64, 64); // Increase radius to 4, higher segments for smoothness
        const material = new THREE.MeshStandardMaterial({ map: earthTexture });
        const earth = new THREE.Mesh(geometry, material);
        earth.castShadow = true; // Enable shadows for the Earth
        scene.add(earth);
    
        // Scale Earth for different screen sizes
        const scaleFactor = window.innerWidth <= 768 ? 0.7 : 1; // Larger Earth on mobile screens
        earth.scale.set(scaleFactor, scaleFactor, scaleFactor);
    
        // Light source
        const light = new THREE.PointLight(0xffffff, 60, 100); // Point light with white color
        light.position.set(0, 0, 10); // Position the light directly in front of the Earth (on the z-axis)
        light.castShadow = true; // Enable shadows for the light
        scene.add(light);
    
        // Ground plane for shadow
        const planeGeometry = new THREE.PlaneGeometry(50, 50);
        const planeMaterial = new THREE.ShadowMaterial({ opacity: 0.9 }); // Shadow material for realistic effect
        const plane = new THREE.Mesh(planeGeometry, planeMaterial);
        plane.rotation.x = -Math.PI / 2; // Rotate plane to lie flat
        plane.position.y = -4; // Position the plane below the Earth
        plane.receiveShadow = true; // Enable shadow reception
        scene.add(plane);
    
        // Animation loop
        const animate = () => {
            requestAnimationFrame(animate);
            earth.rotation.y += 0.005; // Add slow auto-rotation
            renderer.render(scene, camera);
        };
    
        animate();
    
        // Mouse movement for rotation
        const handleMouseMove = (event) => {
            const { innerWidth, innerHeight } = window;
            const mouseX = (event.clientX / innerWidth) * 2 - 1;
            const mouseY = -(event.clientY / innerHeight) * 2 + 1;
    
            earth.rotation.y = mouseX * Math.PI * 0.2; // Adjust rotation sensitivity
            earth.rotation.x = mouseY * Math.PI * 0.2;
        };
    
        window.addEventListener('mousemove', handleMouseMove);
    
        // Cleanup
        return () => {
            if (mountRef.current) {
                mountRef.current.removeChild(renderer.domElement);
            }
            window.removeEventListener('mousemove', handleMouseMove);
        };
    
    }, []); // Empty dependency array to run effect once when component mounts    

    return <div ref={mountRef} style={{ width: '100vw', height: '100vh' }}></div>;
};

export default Earthcss;
