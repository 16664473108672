//Topbar.jsx
import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom'; // Import useLocation
import Logo from '../images/PHOENIXSOLUTION-1.png';
import ArrowBlack from '../images/Icon/btn-arrow-black.svg';
import '../styles/Topbar.scss';

const Topbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isProductsDropdownOpen, setIsProductsDropdownOpen] = useState(false);
  const [isStoreDropdownOpen, setIsStoreDropdownOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [storeItems, setStoreItems] = useState([]); // Store dropdown state
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  
  const BackendURL = process.env.REACT_APP_BACKEND_URL;

  const location = useLocation(); // Get current location

  const fetchData = async (endpoint, setter) => {
    try {
      const response = await fetch(endpoint);
      const data = await response.json();
      setter(data);
    } catch (error) {
      console.error(`Error fetching data from ${endpoint}:`, error);
    }
  };
  
  useEffect(() => {
    fetchData(`${BackendURL}/api/productsname`, setProducts);
    fetchData(`${BackendURL}/api/productsname`, setStoreItems); // Adjusted endpoint for store items
  }, []);
  

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const toggleProductsDropdown = () => {
    setIsProductsDropdownOpen(!isProductsDropdownOpen);
  };

  const toggleStoreDropdown = () => {
    setIsStoreDropdownOpen(!isStoreDropdownOpen);
  };

  return (
    <div className="topbar">
      <div className="announcement">This Site is Under Construction</div>
      <hr className="new4"/>
      <div className="main-menu">
        <NavLink to="/home" className={({ isActive }) => isActive ? 'active' : ''}>
          <img src={Logo} alt="Company Logo" className="logo" />
        </NavLink>
        <button className="nav-toggle" onClick={toggleNav}>
          ☰
        </button>
        <nav className={`nav-links ${isNavOpen ? 'open' : ''}`}>
          <button className="close-btn" onClick={toggleNav}>✖</button>
          <NavLink to="/home" onClick={toggleNav} className={({ isActive }) => isActive ? 'active' : ''}>Home</NavLink>
          <NavLink to="/about" onClick={toggleNav} className={({ isActive }) => isActive ? 'active' : ''}>About</NavLink>

          {/* Products Dropdown */}
          <div
            className={`sub-dropdown ${isProductsDropdownOpen ? 'open' : ''}`}
            onClick={isMobileView ? toggleProductsDropdown : undefined}
            onMouseEnter={!isMobileView ? toggleProductsDropdown : undefined}
            onMouseLeave={!isMobileView ? toggleProductsDropdown : undefined}
          >
            <NavLink to="/products" className={({ isActive }) => `products-dropdown-link ${isActive ? 'active' : ''}`}>
              <span onClick={toggleNav}>Products</span>
            </NavLink>
            <span className="dropdown-arrow">
              <i>
                <img src={ArrowBlack} alt="btn-arrow-white" />
              </i>
            </span>
            {(isProductsDropdownOpen || !isMobileView) && (
              <ul className="sub-popup">
                {products.length > 0 ? (
                  products.map((product) => {
                    // Replace spaces in product name with hyphens and convert to lowercase
                    const productKey = encodeURIComponent(product.name.replace(/\s+/g, '-').toLowerCase());

                    return (
                      <span
                        key={productKey}
                        className="dropdown-item"
                      >
                        <NavLink
                          to={`/products/${productKey}`}
                          onClick={() => {
                            if (window.innerWidth <= 768) {
                              toggleProductsDropdown();
                            }
                            toggleNav();
                          }}
                          className={({ isActive }) => isActive ? 'active' : ''}
                        >
                          {product.name}
                        </NavLink>
                      </span>
                    );
                  })
                ) : (
                  <span className="dropdown-item">No products available</span>
                )}
              </ul>
            )}
          </div>

          {/* Store Dropdown */}
          {/* <div
            className={`sub-dropdown ${isStoreDropdownOpen ? 'open' : ''}`}
            onClick={isMobileView ? toggleStoreDropdown : undefined}
            onMouseEnter={!isMobileView ? toggleStoreDropdown : undefined}
            onMouseLeave={!isMobileView ? toggleStoreDropdown : undefined}
          >
            <NavLink to="/store" className={({ isActive }) => `store-dropdown-link ${isActive ? 'active' : ''}`}>
              <span onClick={toggleNav}> Store </span>
            </NavLink>
            <span className="dropdown-arrow">
              <i>
                <img src={ArrowBlack} alt="btn-arrow-white" />
              </i>
            </span>
            {(isStoreDropdownOpen || !isMobileView) && (
              <ul className="sub-popup">
                {storeItems.length > 0 ? (
                  storeItems.map((product) => {
                    // Replace spaces in product name with hyphens and convert to lowercase
                    const storeKey = encodeURIComponent(product.name.replace(/\s+/g, '-').toLowerCase());

                    return (
                      <span key={storeKey} className="dropdown-item">
                        <NavLink
                          to={`/store/${storeKey}`}
                          onClick={() => {
                            if (window.innerWidth <= 768) {
                              toggleStoreDropdown();
                            }
                            toggleNav();
                          }}
                          className={({ isActive }) => isActive ? 'active' : ''}
                        >
                          {product.name}
                        </NavLink>
                      </span>
                    );
                  })
                ) : (
                  <span className="dropdown-item">No products available</span>
                )}
              </ul>
            )}
          </div> */}

          <NavLink to="/store" onClick={toggleNav} className={({ isActive }) => isActive ? 'active' : ''}>Store</NavLink>
          <NavLink to="/quoterequest" onClick={toggleNav} className={({ isActive }) => isActive ? 'active' : ''}>Request a Quote</NavLink>
          <NavLink to="/contactus" onClick={toggleNav} className={({ isActive }) => isActive ? 'active' : ''}>Contact Us</NavLink>
          <br/>
        </nav>
        {isNavOpen && <div className="overlay" onClick={toggleNav}></div>}
      </div>
    </div>
  );
};

export default Topbar;
