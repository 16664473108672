// main2.jsx

import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../images/PHOENIXSOLUTION-1.png';
import PHOENIXANIME from '../../images/Main/phoenix-fire-bird-animation-template.webm';
//import EarthModel from '../../components-templates/MainPages/EarthModel';
import Earthcss from '../../components-templates/MainPages/Earthcss';
import '../../styles/MainPages/main2.scss';
import ArrowOrange from '../../images/Icon/btn-arrow-orange.svg';

const Main2 = () => {
    const [showWelcome, setShowWelcome] = useState(true);

    // Hide the "Welcome" text after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowWelcome(false);
        }, 5000); // 5000 ms = 5 seconds

        return () => clearTimeout(timer); // Cleanup timeout
    }, []);

    const scrollToMainPage = () => {
        const mainPage = document.getElementById('main-page-description');
        if (mainPage) {
            mainPage.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="main-page-2" id="main-page">
            <div className="announcement">
                <span>Our website is updating security ,we will be online soon.</span>
                <span>This Site is Under Construction</span>
            </div>
            <div className="main-page-logo-section">
                <NavLink to="/home">
                    <img src={Logo} alt="Company Logo" className="logo" />
                </NavLink>

                <div className="scroll-down-arrow" onClick={scrollToMainPage}>
                    {/* &#x25BC; Downward arrow symbol */}
                    <i>
                        &nbsp;&nbsp;<img src={ArrowOrange} alt="btn-arrow-white" />
                    </i>
                </div>
            </div>

            <div className="main-page-animation">
                <video width="100%" controls>
                    <source src={PHOENIXANIME} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>

            <div className="main-page-description" id="main-page-description">
                <div className="description">
                    <section className="wrapper">
                        <div id="stars"></div>
                        <div id="stars2"></div>
                        <div id="stars3"></div>
                    </section>

                    <div className="main-page-description-title">
                        <div className="title">
                            <h1 className="typing">PHOENIX SOLUTION</h1>
                        </div>
                    </div>

                    {showWelcome ? (
                        <div className="main-page-description-title-Welcome">
                            <div className="title-Welcome">
                                <h1 className="typing">Welcome to Phoenix Solution</h1>
                            </div>
                        </div>
                    ) : (
                        <div className="main-page-description-text">
                            <div className="main-page-description-text-about">
                                <h1>About Phoenix Solution</h1>
                                <p>
                                    Phoenix Solution, established in 2016 and strategically situated in the heart of Silicon Valley,
                                    has swiftly become a foremost global purveyor of silicon wafers and cutting-edge semiconductor materials.
                                    Providing service to an extremely diverse and demanding customer base encompassing research laboratories to
                                    state-of-the-art high-volume manufacturing facilities.
                                </p>
                                <div className="btn-home" onClick={() => window.scrollTo(0, 0)} >
                                    <NavLink to="/home" onClick={() => window.scrollTo(0, 0)} >
                                        <button>visit A website</button>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="main-page-description-text-connect">
                                <h3>In the mean time connect with us with the information below</h3>
                                <p className="typingP"><i className="fas fa-map-marker-alt"></i> Bethesda,Maryland Washington D.C., US 20899</p>
                                <br />
                                <p className="typingP"><i className="fas fa-envelope"></i> info@phoenixsolution.us</p>
                                <br />
                                <p className="typingP"><i className="fas fa-phone-alt"></i> +91 8780490582</p>
                                <br />
                                <p className="typingP"><i className="fas fa-clock"></i> Monday – Friday 9:00 a.m. to 6:00 p.m.</p>
                            </div>

                            <div className="main-page-description-text-business-area">
                                <div className="business-area">
                                    <h3>Business Area</h3>
                                    <br />
                                    <p><i className="fas fa-map-marker-alt"></i> Australia</p>
                                    <p><i className="fas fa-map-marker-alt"></i> Canada</p>
                                    <p><i className="fas fa-map-marker-alt"></i> Germany</p>
                                    <p><i className="fas fa-map-marker-alt"></i> India</p>
                                    <p><i className="fas fa-map-marker-alt"></i> Isreal</p>
                                    <p><i className="fas fa-map-marker-alt"></i> Italy</p>
                                    <p><i className="fas fa-map-marker-alt"></i> Japan</p>
                                    <p><i className="fas fa-map-marker-alt"></i> Jordan</p>
                                    <p><i className="fas fa-map-marker-alt"></i> Republic of Korea</p>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="main-page-description-animation">
                        {/* <EarthModel /> */}
                        <Earthcss />
                    </div>

                        <div className="main-page-description-title-Welcome-1">
                            <div className="title-Welcome">
                                <h1 className="typing">Welcome to Phoenix Solution</h1>
                            </div>
                        </div>
                        <div className="main-page-description-text-1">
                            <div className="main-page-description-text-about-1">
                                <h1>About Phoenix Solution</h1>
                                <p>
                                    Phoenix Solution, established in 2016 and strategically situated in the heart of Silicon Valley,
                                    has swiftly become a foremost global purveyor of silicon wafers and cutting-edge semiconductor materials.
                                    Providing service to an extremely diverse and demanding customer base encompassing research laboratories to
                                    state-of-the-art high-volume manufacturing facilities.
                                </p>
                                <div className="btn-home" onClick={() => window.scrollTo(0, 0)} >
                                    <NavLink to="/home" onClick={() => window.scrollTo(0, 0)} >
                                        <button>visit A website</button>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="main-page-description-text-connect-1">
                                <h3>In the mean time connect with us with the information below</h3>
                                <p className="typingP"><i className="fas fa-map-marker-alt"></i> Bethesda,Maryland Washington D.C., US 20899</p>
                                <p className="typingP"><i className="fas fa-envelope"></i> info@phoenixsolution.us</p>
                                <p className="typingP"><i className="fas fa-phone-alt"></i> +91 8780490582</p>
                                <p className="typingP"><i className="fas fa-clock"></i> Monday – Friday 9:00 a.m. to 6:00 p.m.</p>
                            </div>

                            <div className="main-page-description-text-business-area-1">
                                <div className="business-area">
                                    <h3>Business Area</h3>
                                    <p><i className="fas fa-map-marker-alt"></i> Australia</p>
                                    <p><i className="fas fa-map-marker-alt"></i> Canada</p>
                                    <p><i className="fas fa-map-marker-alt"></i> Germany</p>
                                    <p><i className="fas fa-map-marker-alt"></i> India</p>
                                    <p><i className="fas fa-map-marker-alt"></i> Isreal</p>
                                    <p><i className="fas fa-map-marker-alt"></i> Italy</p>
                                    <p><i className="fas fa-map-marker-alt"></i> Japan</p>
                                    <p><i className="fas fa-map-marker-alt"></i> Jordan</p>
                                    <p><i className="fas fa-map-marker-alt"></i> Republic of Korea</p>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    );
};

export default Main2;
